import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "Home | One Squared",
      dynamicParam: "-",
      metaTags: [
        {
          name: "description",
          content:
            "One Squared is a brand new eatery located beside Bayside shopping centre! We offer a wide array of food options covering breakfast, lunch & dinner! We are currently open from 8am to 5pm.",
        },
        {
          property: "og:description",
          content:
            "One Squared is a brand new eatery located beside Bayside shopping centre! We offer a wide array of food options covering breakfast, lunch & dinner! We are currently open from 8am to 5pm.",
        },
      ],
    },
    // component: () => import("../components/layout/UnderCon.vue"),
    component: () => import("../views/Home.vue"),
  },
  //
  //
  {
    path: "/menu",
    name: "Menu",
    meta: {
      title: "Menu | One Squared",
      dynamicParam: "-",
      metaTags: [
        {
          name: "description",
          content:
            "View our menu for breakfast, lunch & dinner at One Square Bayside",
        },
        {
          property: "og:description",
          content:
            "One Squared is a brand new eatery located beside Bayside shopping centre! We offer a wide array of food options covering breakfast, lunch & dinner! We are currently open from 8am to 5pm.",
        },
      ],
    },
    component: () => import("../views/Menu.vue"),
  },
  //
  //
  {
    path: "/breakfast",
    name: "Breakfast",
    meta: {
      title: "Breakfast | One Squared",
      dynamicParam: "-",
      metaTags: [
        {
          name: "description",
          content:
            "Come join us for breakfast and enjoy one of our many dishes such as a full Irish breakfast, scrambled eggs on toast, overnight oats, protein pancakes & don't forget to wash it down with your favourite coffee or tea! See menu for all options.",
        },
        {
          property: "og:description",
          content:
            "Come join us for breakfast and enjoy one of our many dishes such as a full Irish breakfast, scrambled eggs on toast, overnight oats, protein pancakes & don't forget to wash it down with your favourite coffee or tea! See menu for all options.",
        },
      ],
    },
    component: () => import("../views/Breakfast.vue"),
  },
  //
  //
  {
    path: "/lunch",
    name: "Lunch",
    meta: {
      title: "Lunch | One Squared",
      dynamicParam: "-",
      metaTags: [
        {
          name: "description",
          content:
            "Come and enjoy lunch with us with our all day menu! salads, pizza, burgers you name it we got it! See menu for all options!",
        },
        {
          property: "og:description",
          content:
            "Come and enjoy lunch with us with our all day menu! salads, pizza, burgers you name it we got it! See menu for all options!",
        },
      ],
    },
    component: () => import("../views/Lunch.vue"),
  },
  //
  //
  {
    path: "/dinner",
    name: "",
    meta: {
      title: "Dinner | One Squared",
      dynamicParam: "-",
      metaTags: [
        {
          name: "description",
          content: "",
        },
        {
          property: "og:description",
          content: "",
        },
      ],
    },
    component: () => import("../views/Dinner.vue"),
  },
  //
  //
  {
    path: "/contact",
    name: "contact",
    meta: {
      title: "Contact | One Squared",
      dynamicParam: "-",
      metaTags: [
        {
          name: "description",
          content:
            "We are open for dinner with our all day menu catering for starters, mains & of course desert! See menu for all our options!",
        },
        {
          property: "og:description",
          content:
            "We are open for dinner with our all day menu catering for starters, mains & of course desert! See menu for all our options!",
        },
      ],
    },
    component: () => import("../views/Contact.vue"),
  },
  //
  {
    path: "/admin",
    name: "Admin",
    meta: {
      title: "Admin | ",
      dynamicParam: "-",
      metaTags: [
        {
          name: "description",
          content: "",
        },
        {
          property: "og:description",
          content: "",
        },
      ],
    },
    component: () => import("../views/Admin.vue"),
  },
  //
  {
    path: "/:pathMatch(.*)*",
    name: "Page Not Found",
    meta: {
      title: "Page Not Found | One Squared",
      dynamicParam: "-",
    },
    component: () => import("../views/NotFound.vue"),
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  if (to.hash) {
    setTimeout(() => {
      const element = document.getElementById(to.hash.replace(/#/, ""));
      if (element && element.scrollIntoView) {
        element.scrollIntoView({
          block: "end",
          behavior: "smooth",
        });
      }
    }, 500);
    return {
      el: to.hash,
    };
  } else if (savedPosition) {
    return savedPosition;
  }
  return {
    top: 0,
  };
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  // window.console.log(to.path)
  // if (localStorage.getItem('roleID') === null && to.path != '/login') {
  //   window.location.href = '/login'
  // }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title && r.meta.dynamicParam);
  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    if (
      nearestWithTitle.meta.dynamicParam === undefined ||
      nearestWithTitle.meta.dynamicParam === "-"
    ) {
      document.title = nearestWithTitle.meta.title;
    } else {
      const pageTitle = nearestWithTitle.meta.title.replace(
        ":" + nearestWithTitle.meta.dynamicParam,
        to.params[nearestWithTitle.meta.dynamicParam]
          .replace(/[^a-zA-Z0-9]/g, " ")
          .replace(/\b[a-z]/g, (x) => x.toUpperCase())
      );
      document.title = pageTitle;
    }
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));
  next();
});

export default router;
