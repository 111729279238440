<template>
  <Nav />
  <router-view />
  <Footer />
</template>

<script>
import Nav from "./components/layout/Nav.vue";

import Footer from "./components/layout/Footer.vue";
import AOS from "aos";

AOS.init({ duration: 1500 });

export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Bellefair&family=Libre+Baskerville&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200&display=swap");

h1,
h2,
h3,
.rl {
  font-family: "Bellefair", serif;
  font-family: "Libre Baskerville", serif;
}

h4,
p {
  font-family: "Josefin Sans", sans-serif;
  font-weight: "200";
}

/* w */
/* font-family: 'DM Sans', sans-serif; */
/* font-family: 'DM Mono', sans-serif;
} */
</style>
